<template>
  <div>
    <AppHeader v-if="!isMobile">
      <template v-slot:title>
        <h1>{{ $t("login.log-in") }}</h1>
      </template>
    </AppHeader>

    <main :style="{ paddingBottom: footerHeight + 'px' }">
      <PacketaLogo :description="$t('login.logo-description')" />
      <form class="container" @submit.prevent="onSubmit">
        <label>
          {{ $t("login.email") }}<span class="mandatory">*</span>
          <input type="email" v-model="email" />
        </label>
        <label>
          {{ $t("login.password") }}<span class="mandatory">*</span>
          <input type="password" v-model="password" />
        </label>
        <router-link
          class="smaller"
          :to="{ name: 'forgot-password', query: { platform, device } }"
          >{{ $t("login.forgot-password") }}</router-link
        >
        <button
          :class="[
            'btn btn-primary',
            !email || !password ? 'btn-grey disable' : 'btn-red'
          ]"
        >
          {{ $t("login.log-in") }}
        </button>
      </form>
      <Errors :errors="errors" />
    </main>

    <footer ref="footer">
      <p class="smaller" v-html="$t('login.contact-support')"></p>
    </footer>
    <Spinner v-if="loading" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Errors from "@/components/Errors";
import AppHeader from "@/components/AppHeader";
import { setActualFooterHeight } from "@/mixins/footer";
import { getParams } from "@/mixins/platform-params.js";
import Spinner from "@/components/Spinner";
import PacketaLogo from "@/components/PacketaLogo";

export default {
  name: "Login",
  mixins: [setActualFooterHeight, getParams],
  components: { Errors, AppHeader, Spinner, PacketaLogo },
  data() {
    return {
      email: null,
      password: null
    };
  },

  beforeRouteLeave(to, from, next) {
    this.clearErrors();
    next();
  },

  computed: {
    ...mapState(["errors", "loading"])
  },

  methods: {
    ...mapActions("auth", ["login"]),
    ...mapMutations(["clearErrors"]),

    async onSubmit() {
      const response = await this.login({
        email: this.email,
        password: this.password
      });
      if (!response) return;

      this.$router.replace({
        name: "dashboard",
        query: {
          platform: this.platform,
          device: this.device
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
main {
  padding: 0 40px;

  .logo {
    img {
      height: 51px;
      width: 200px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    label:first-child {
      margin-bottom: 13px;
    }

    a {
      align-self: flex-end;
      margin-top: 7px;
    }

    button {
      margin-top: 19px;
    }
  }
}

footer {
  padding: 16px;
}
</style>
